<template>
  <div>
    <Sedirector_app_bar/>
    <v-container>
      <base-material-card
        icon="mdi-clipboard-text"
        title="กำหนดการสถานศึกษา"
        class="px-5 py-3"
        color="info"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" lg="6" class="text-right">
              <v-btn
                large
                right
                depressed
                color="primary"
                @click.native="period_sedirectorAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="period_sedirectors"
          :search="search"
        >
          <template v-slot:item.period_sedirector_start="{ item }">
            {{
              item.period_sedirector_start
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>
          <template v-slot:item.period_sedirector_stop="{ item }">
            {{
              item.period_sedirector_stop
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>
          <template v-slot:item.period_sedirector_dmycal="{ item }">
            {{
              item.period_sedirector_dmycal
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>
          <template v-slot:item.period_sedirector_enable="{ item }">
            <v-icon
              large
              color="green darken-2"
              v-if="item.period_sedirector_enable === '1'"
              >mdi-alarm-light</v-icon
            >
            <v-icon v-if="item.period_sedirector_enable === '0'"
              >mdi-alarm-light-outline</v-icon
            >
          </template>

          <template v-slot:item.period_sedirector_process="{ item }">
            <v-icon
              large
              color="green darken-2"
              v-if="item.period_sedirector_process === '1'"
              >mdi-alarm-light</v-icon
            >
            <v-icon v-if="item.period_sedirector_process === '0'"
              >mdi-alarm-light-outline</v-icon
            >
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              large
              color="yellow"
              @click.stop="
                period_sedirectorEdit(
                  item.period_sedirector_year,
                  item.period_sedirector_times
                )
              "
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              color="red"
              large
              @click.stop="
                period_sedirectorDelete(
                  item.period_sedirector_year,
                  item.period_sedirector_times,
                  item.period_sedirector_type
                )
              "
            >
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addperiod_sedirectordialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="addperiod_sedirectordialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลปี"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addperiod_sedirectorform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-select
                        v-model="addperiod_sedirector.period_sedirector_year"
                        :items="period_sedirectorselect"
                        item-text="text"
                        item-value="value"
                        label="เลือกปีการศึกษา"
                        single-line
                      >
                      </v-select>
                      <v-spacer></v-spacer>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        type="number"
                        label="ครั้งที่"
                        v-model="addperiod_sedirector.period_sedirector_times"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md4>
                      <v-text-field
                        type="date"
                        label="วันที่เปิดระบบ"
                        v-model="addperiod_sedirector.period_sedirector_start"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-text-field
                        type="date"
                        label="วันที่ปิดระบบ"
                        v-model="addperiod_sedirector.period_sedirector_stop"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-text-field
                        type="date"
                        label="วันที่สิ้นสุดการคำนวณอายุงาน"
                        v-model="addperiod_sedirector.period_sedirector_dmycal"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-switch
                        v-model="
                          addperiod_sedirector.period_sedirector_enable_sw
                        "
                        label="เปิดใช้งานสถานศึกษา"
                      ></v-switch>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-switch
                        v-model="
                          addperiod_sedirector.period_sedirector_process_sw
                        "
                        label="เปิดใช้ระบบอาชีวศึกษาจังหวัด"
                      ></v-switch>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        type="time"
                        label="เวลาทำการสิ้นสุด"
                        v-model="addperiod_sedirector.period_sedirector_timeend"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addperiod_sedirectordialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addperiod_sedirectorSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deleteperiod_sedirectordialog -->
      <v-layout>
        <v-dialog
          v-model="deleteperiod_sedirectordialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลปี"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deleteperiod_sedirectorform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบรายการ
                          {{ editperiod_sedirector.period_sedirector_year }} :
                          {{ editperiod_sedirector.period_sedirector_year }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deleteperiod_sedirectordialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deleteperiod_sedirectorSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editperiod_sedirectordialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editperiod_sedirectordialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลปี"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editperiod_sedirectorform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-select
                      readonly
                        v-model="editperiod_sedirector.period_sedirector_year"
                        :items="period_sedirectorselect"
                        item-text="text"
                        item-value="value"
                        label="เลือกปีการศึกษา"
                        single-line
                      >
                      </v-select>
                      <v-spacer></v-spacer>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                      readonly
                        type="number"
                        label="ครั้งที่"
                        v-model="editperiod_sedirector.period_sedirector_times"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md4>
                      <v-text-field
                        type="date"
                        label="วันที่เปิดระบบ"
                        v-model="editperiod_sedirector.period_sedirector_start"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-text-field
                        type="date"
                        label="วันที่ปิดระบบ"
                        v-model="editperiod_sedirector.period_sedirector_stop"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-text-field
                        type="date"
                        label="วันที่สิ้นสุดการคำนวณอายุงาน"
                        v-model="editperiod_sedirector.period_sedirector_dmycal"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-switch
                        v-model="
                          editperiod_sedirector.period_sedirector_enable_sw
                        "
                        label="เปิดใช้งานสถานศึกษา"
                      ></v-switch>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-switch
                        v-model="
                          editperiod_sedirector.period_sedirector_process_sw
                        "
                        label="เปิดใช้ระบบอาชีวศึกษาจังหวัด"
                      ></v-switch>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        type="time"
                        label="เวลาทำการสิ้นสุด"
                        v-model="
                          editperiod_sedirector.period_sedirector_timeend
                        "
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editperiod_sedirectordialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editperiod_sedirectorSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        auto-height
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">
          Close
        </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import Sedirector_app_bar from "../../components/admin/sedirector_app_bar.vue";
export default {
    name: "period_sedirector",
    data() {
        return {
            VueMoment,
            moment,
            loading: true,
            ApiKey: "HRvec2021",
            valid: true,
            addperiod_sedirectordialog: false,
            editperiod_sedirectordialog: false,
            deleteperiod_sedirectordialog: false,
            snackbar: {
                show: false,
                color: "",
                timeout: 5000,
                icon: "",
                text: ""
            },
            period_sedirector_types: [
                { text: "รวบรวมคำร้องขอย้าย สายการสอน", value: "teacher_request" },
                { text: "เงือนไขการย้าย", value: "movement_college" },
                { text: "อัตรากำลัง", value: "update_college" },
                {
                    text: "ขอความเห็นจากคณะกรรมการสถานศึกษาย้ายสายบริหาร",
                    value: "discuss_college"
                },
                {
                    text: "ขอความเห็นจากคณะกรรมการสถานศึกษาย้ายสายการสอน",
                    value: "discuss_teacher"
                },
                { text: "รายงานตัว การย้ายครู", value: "reportmove_teacher" }
            ],
            period_sedirectors: [],
            addperiod_sedirector: {},
            editperiod_sedirector: {},
            search: "",
            pagination: {},
            headers: [
                { text: "ครั้งที่", align: "center", value: "period_sedirector_times" },
                { text: "ปี", align: "center", value: "period_sedirector_year" },
                {
                    text: "เปิดระบบวันที่",
                    align: "center",
                    value: "period_sedirector_start"
                },
                {
                    text: "ปิดระบบวันที่",
                    align: "center",
                    value: "period_sedirector_stop"
                },
                {
                    text: "คำนวณสิ้นสุดวันที่",
                    align: "center",
                    value: "period_sedirector_dmycal"
                },
                {
                    text: "สถานะ",
                    align: "center",
                    value: "period_sedirector_enable"
                },
                {
                    text: "สถานะประมวลผล",
                    align: "center",
                    value: "period_sedirector_process"
                },
                {
                    text: "สถานะเวลาปิดระบบ",
                    align: "center",
                    value: "period_sedirector_timeend"
                },
                {
                    text: "แก้ไข",
                    align: "center",
                    value: "actions",
                    icon: "mdi-file-document-edit"
                },
                {
                    text: "ลบ",
                    align: "center",
                    value: "action_s",
                    icon: "mdi-delete-forever"
                }
            ],
            rowsperpage: [
                25,
                50,
                100,
                {
                    text: "All",
                    value: -1
                }
            ],
            period_sedirectorselect: [
                {
                    value: "2566",
                    text: "2566"
                },
                {
                    value: "2567",
                    text: "2567"
                },
                {
                    value: "2568",
                    text: "2568"
                },
                {
                    value: "2569",
                    text: "2569"
                },
                {
                    value: "2570",
                    text: "2570"
                }
            ],
            data_syslog: {}
        };
    },
    async mounted() {
        this.period_sedirectorQueryAll();
    },
    methods: {
        async period_sedirectorQueryAll() {
            this.loading = true;
            let result = await this.$http
                .post("period_sedirector.php", {
                ApiKey: this.ApiKey
            })
                .finally(() => (this.loading = false));
            this.period_sedirectors = result.data;
        },
        async period_sedirectorAdd() {
            this.addperiod_sedirector = {};
            this.addperiod_sedirector.period_sedirector_year = new Date()
                .getFullYear()
                .toString();
            this.addperiod_sedirector.period_sedirector_start = new Date()
                .toISOString()
                .substr(0, 10);
            this.addperiod_sedirector.period_sedirector_stop = new Date()
                .toISOString()
                .substr(0, 10);
            this.addperiod_sedirector.period_sedirector_enable_sw = false;
            this.adddialog = true;
            this.addperiod_sedirectordialog = true;
        },
        async addperiod_sedirectorSubmit() {
            if (this.addperiod_sedirector.period_sedirector_enable_sw == true) {
                this.addperiod_sedirector.period_sedirector_enable = "1";
            }
            else {
                this.addperiod_sedirector.period_sedirector_enable = "0";
            }
            if (this.addperiod_sedirector.period_sedirector_process_sw == true) {
                this.addperiod_sedirector.period_sedirector_process = "1";
            }
            else {
                this.addperiod_sedirector.period_sedirector_process = "0";
            }
            this.addperiod_sedirector.ApiKey = this.ApiKey;
            let result = await this.$http.post("period_sedirector.insert.php", this.addperiod_sedirector);
            if (result.data.status == true) {
                Swal.fire({
                    icon: "success",
                    title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
                    showConfirmButton: false,
                    timer: 1500
                });
                this.period_sedirectorQueryAll();
                let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
                this.data_syslog.ApiKey = this.ApiKey;
                this.data_syslog.user_account = userSession.user_name;
                this.data_syslog.event_log = "insert";
                this.data_syslog.page_log = "period_sedirector";
                this.data_syslog.table_log = "period_sedirector";
                this.data_syslog.date_times = this.date_today_log;
                await this.$http.post("data_syslog.insert.php", this.data_syslog);
            }
            else {
                Swal.fire({
                    icon: "warning",
                    title: "ดำเนินการผิดพลาด",
                    showConfirmButton: false,
                    timer: 1500
                });
            }
            this.addperiod_sedirectordialog = false;
        },
        async period_sedirectorEdit(period_sedirector_year, period_sedirector_times) {
            let result = await this.$http.post("period_sedirector.php", {
                ApiKey: this.ApiKey,
                period_sedirector_year: period_sedirector_year,
                period_sedirector_times: period_sedirector_times
            });
            this.editperiod_sedirector = result.data;
            if (this.editperiod_sedirector.period_sedirector_enable == 1) {
                this.editperiod_sedirector.period_sedirector_enable_sw = true;
            }
            else {
                this.editperiod_sedirector.period_sedirector_enable_sw = false;
            }
            if (this.editperiod_sedirector.period_sedirector_process == 1) {
                this.editperiod_sedirector.period_sedirector_process_sw = true;
            }
            else {
                this.editperiod_sedirector.period_sedirector_process_sw = false;
            }
            this.editperiod_sedirectordialog = true;
        },
        async editperiod_sedirectorSubmit() {
            if (this.editperiod_sedirector.period_sedirector_enable_sw == true) {
                this.editperiod_sedirector.period_sedirector_enable = "1";
            }
            else {
                this.editperiod_sedirector.period_sedirector_enable = "0";
            }
            if (this.editperiod_sedirector.period_sedirector_process_sw == true) {
                this.editperiod_sedirector.period_sedirector_process = "1";
            }
            else {
                this.editperiod_sedirector.period_sedirector_process = "0";
            }
            this.editperiod_sedirector.ApiKey = this.ApiKey;
            let result = await this.$http.post("period_sedirector.update.php", this.editperiod_sedirector);
            
            if (result.data.status == true) {
                this.period_sedirector = result.data;
                Swal.fire({
                    icon: "success",
                    title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
                    showConfirmButton: false,
                    timer: 1500
                });
                this.period_sedirectorQueryAll();
                let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
                this.data_syslog.ApiKey = this.ApiKey;
                this.data_syslog.user_account = userSession.user_name;
                this.data_syslog.event_log = "update";
                this.data_syslog.page_log = "period_sedirector";
                this.data_syslog.table_log = "period_sedirector";
                this.data_syslog.date_times = this.date_today_log;
                await this.$http.post("data_syslog.insert.php", this.data_syslog);
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "ดำเนินการผิดพลาด",
                    showConfirmButton: false,
                    timer: 1500
                });
            }
            this.editperiod_sedirectordialog = false;
        },
        async period_sedirectorDelete(period_sedirector_year, period_sedirector_times, period_sedirector_type) {
            let result = await this.$http.post("period_sedirector.php", {
                ApiKey: this.ApiKey,
                period_sedirector_year: period_sedirector_year,
                period_sedirector_times: period_sedirector_times,
                period_sedirector_type: period_sedirector_type
            });
            this.editperiod_sedirector = result.data;
            this.deleteperiod_sedirectordialog = true;
        },
        async deleteperiod_sedirectorSubmit() {
            if (this.$refs.deleteperiod_sedirectorform.validate()) {
                this.editperiod_sedirector.ApiKey = this.ApiKey;
                let result = await this.$http.post("period_sedirector.delete.php", this.editperiod_sedirector);
                if (result.data.status == true) {
                    this.period_sedirector = result.data;
                    Swal.fire({
                        icon: "success",
                        title: "ดำเนินการลบข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.period_sedirectorQueryAll();
                    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
                    this.data_syslog.ApiKey = this.ApiKey;
                    this.data_syslog.user_account = userSession.user_name;
                    this.data_syslog.event_log = "delete";
                    this.data_syslog.page_log = "period_sedirector";
                    this.data_syslog.table_log = "period_sedirector";
                    this.data_syslog.date_times = this.date_today_log;
                    await this.$http.post("data_syslog.insert.php", this.data_syslog);
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "ดำเนินการลบผิดพลาด",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                this.deleteperiod_sedirectordialog = false;
            }
        }
    },
    computed: {
        pages() {
            if (this.pagination.rowsPerPage == null ||
                this.pagination.totalItems == null)
                return 0;
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
        },
        date_today_log() {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            let yyyy = today.getFullYear() + 543;
            let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            today = dd + "/" + mm + "/" + yyyy + "/" + time;
            return today;
        }
    },
    components: { Sedirector_app_bar }
};
</script>
